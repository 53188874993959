<template>
  <div>
    <Widget class="mb-2">
      <WidgetHeader v-if="filters && filters.id" class="bg-gray-200">
        <template slot="rawContent">
          <div class="grid grid-cols-1 sm:grid-cols-8 gap-1 w-full">
            <Superselect
              title="Provider"
              v-model="filters.id.value"
              :modifiers="filters.id.modifiers"
              :options="providerOptions"
              track-by="id"
              label="label"
              :close-on-select="true"
            ></Superselect>
            <Superselect
              title="Type"
              v-model="filters.type.value"
              :modifiers="filters.type.modifiers"
              :options="typeOptions"
              track-by="id"
              label="label"
              :close-on-select="true"
            ></Superselect>
            <Superselect
              title="IP Type"
              v-model="filters.serverIpType.value"
              :modifiers="filters.serverIpType.modifiers"
              :options="ipTypeOptions"
              track-by="id"
              label="label"
              :close-on-select="true"
            ></Superselect>
            <Superselect
              title="Server IPs"
              :force-edit="true"
              :edit-placeholder="``"
              v-model="filters.serverIp.value"
              :modifiers="filters.serverIp.modifiers"
              track-by="id"
              label="label"
            >
              <template v-slot:footer>
                <div class="mt-1 flex">
                  <YToggle
                    v-model="filters.serverIp.settings.filtersServers"
                    v-tooltip="`Filter Servers`"
                    class="mr-2"
                  >
                    <template v-slot:true>
                      <Icon name="desktopMac" class="text-blue-600" :size="4" />
                    </template>
                    <template v-slot:false>
                      <Icon name="desktopMac" class="text-gray-600" :size="4" />
                    </template>
                  </YToggle>
                  <YToggle
                    v-model="filters.serverIp.settings.filtersIps"
                    v-tooltip="`Filter IPs`"
                  >
                    <template v-slot:true>
                      <Icon
                        name="videoInputAntenna"
                        class="text-blue-600"
                        :size="4"
                      />
                    </template>
                    <template v-slot:false>
                      <Icon
                        name="videoInputAntenna"
                        class="text-gray-600"
                        :size="4"
                      />
                    </template>
                  </YToggle>
                </div>
              </template>
            </Superselect>
          </div>
        </template>
      </WidgetHeader>
      <WidgetHeader
        class="bg-opacity-faded"
        style="border-top: 1px solid #D9D9D9;"
      >
        <template slot="rawContent">
          <div class="mb-4 mb-sm-0"></div>
          <div>
            <YButton
              class="text-blue-600 hover:bg-gray-100 mr-3"
              @click="resetFilters()"
              >Reset Filters</YButton
            >
          </div>
        </template>
      </WidgetHeader>
    </Widget>
    <div class="flex justify-between mb-2 items-center">
      <div><h5 class="mb-0 font-bold text-lg">Providers</h5></div>
      <div>
        <YButton
          class="text-blue-600 hover:bg-blue-100"
          @click="popCreateProviderModal"
        >
          Add Provider
        </YButton>
      </div>
    </div>
    <div v-if="$apollo.queries.providers.loading" class="text-center p-6">
      <Spinner color="green" :size="12" />
    </div>
    <div v-else-if="providers.length == 0" class="p-6 text-center text-muted">
      <span style="text-shadow: 0px 1px 0px rgba(255,255,255,0.5)"
        >No providers found</span
      >
    </div>
    <template v-else-if="providers.length > 0">
      <provider
        v-for="(provider, index) in providers"
        :key="provider.name"
        v-model="providers[index]"
        class="mb-1"
      >
        <servers
          :filters="getServerFiltersByProvider(provider.id)"
          :show-servers-by-default="providers.length == 1"
          :provider="provider"
        ></servers>
      </provider>
    </template>
  </div>
</template>

<script>
import Provider from './Provider'
import ProviderCreate from './ProviderCreate'
import gql from 'graphql-tag'
import YToggle from '../yToggle'
import Servers from './Servers'

export default {
  components: {
    Provider,
    YToggle,
    Servers
  },
  props: {},
  apollo: {
    providerOptions: {
      query: gql`
        {
          providerOptions: ydnsProviders {
            id
            label: name
          }
        }
      `
    },
    providers: {
      query: gql`
        query getProviders(
          $filters: YdnsProviderFilterInput
          $serverFilters: YdnsServerFilterInput
          $ipFilters: YdnsIpFilterInput
        ) {
          providers: ydnsProviders(filters: $filters) {
            id
            name
            type
            comments {
              id
              message
              commenter {
                name
              }
              createdAt
            }
            servers(filters: $serverFilters) {
              id
              type
              ips(filters: $ipFilters) {
                id
                records {
                  id
                  domain {
                    id
                  }
                  type
                  content
                  subdomain
                  ttl
                  priority
                }
                version
                enabled
              }
              ipSummary {
                ipv4Range {
                  start {
                    id
                  }
                  end {
                    id
                  }
                }
                ipv6Range {
                  start {
                    id
                  }
                  end {
                    id
                  }
                }
                count
                enabledCount
                enabledIps {
                  id
                  version
                  enabled
                }
              }
              provider {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        return {
          filters: this.providerFiltersGql,
          serverFilters: this.serverFiltersGql,
          ipFilters: this.ipFiltersGql
        }
      },
      deep: true,
      result(result) {
        this.providers = JSON.parse(JSON.stringify(result.data.providers))
      }
    }
  },
  data() {
    return {
      providers: [],
      isLoading: false,
      typeOptions: [
        { id: 'general', label: 'General' },
        { id: 'bulletproof', label: 'Bulletproof' }
      ],
      ipTypeOptions: [
        { id: 'mini', label: 'Mini' },
        { id: 'redirect', label: 'Redirect' }
      ],
      providerOptions: [],
      filters: this.$store.getters['ydns/providerFilters']
    }
  },
  computed: {
    providerFiltersGql() {
      return this.$store.getters['ydns/providerFiltersGql']
    },
    serverFiltersGql() {
      return this.$store.getters['ydns/providerServerFiltersGql']
    },
    ipFiltersGql() {
      return this.$store.getters['ydns/ProviderServerIpFiltersGql']
    }
  },
  watch: {
    filters: {
      handler: function(newValue) {
        this.$store.dispatch('ydns/updateProviderFilters', newValue)
      },
      deep: true
    }
  },
  mounted() {
    this.$events.listen('refreshYdnsProviders', this.refresh)
  },
  beforeDestroy() {
    this.$events.remove('refreshYdnsProviders')
  },
  updated() {},

  methods: {
    getServerFiltersByProvider(providerId) {
      return this._.assignIn(
        {},
        this.serverFiltersGql ? this.serverFiltersGql : null,
        { provider: { value: [providerId] } }
      )
    },
    resetFilters() {
      this.filters = JSON.parse(
        JSON.stringify(this.$store.getters['ydns/providerFiltersDefault'])
      )
    },
    refresh() {
      this.$apollo.queries.providers.refetch()
    },
    popCreateProviderModal() {
      this.$modal.show(
        ProviderCreate,
        {},
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    }
  }
}
</script>

<style>
.ydns-filter-radios {
  margin-bottom: -1rem;
}
</style>
