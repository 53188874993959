var render = function render(){
  var _vm$server$comments$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-2 p-3",
    staticStyle: {
      "border": "1px solid rgba(0,0,0,0.1)",
      "border-radius": "3px",
      "box-shadow": "1px 1px 4px rgba(0,0,0,0.06)"
    }
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('div', {
    staticClass: "flex-grow-md-1 w-full w-md-auto"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('div', [_c('div', {
    staticClass: "flex items-center mb-2"
  }, [_c('div', {
    staticClass: "mr-3 flex gap-2"
  }, [_c('h6', {
    staticClass: "mb-0 font-bold"
  }, [_vm.server.type == 'proxy' ? _c('a', {
    attrs: {
      "href": 'http://' + _vm.server.serverIp + '/haproxy?stats'
    }
  }, [_c('Icon', {
    staticClass: "inline -mx-1",
    attrs: {
      "name": "information",
      "size": 6
    }
  })], 1) : _vm._e(), _vm.server.hostname ? [_vm._v(" " + _vm._s(_vm.server.hostname) + " ")] : [_vm._v(" " + _vm._s(_vm.server.provider.name) + " - " + _vm._s(_vm.server.id) + " ")]], 2), _c('div', {
    staticClass: "ml-3 flex gap-2"
  }, [_c('v-popover', {
    ref: "commentPopover",
    attrs: {
      "offset": "10",
      "placement": "right-start"
    }
  }, [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Add Comment',
      expression: "'Add Comment'"
    }],
    attrs: {
      "type": "button"
    }
  }, [_c('Icon', {
    staticClass: "h-5 w-5 text-inverse-500",
    attrs: {
      "name": "addComment"
    }
  })], 1), _c('template', {
    slot: "popover"
  }, [_c('form', {
    staticClass: "p-4 bg-gray-100 flex flex-col"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.commentMessage,
      expression: "commentMessage"
    }],
    staticClass: "px-3 py-3 w-full rounded mb-2 border block",
    attrs: {
      "placeholder": "Type here..."
    },
    domProps: {
      "value": _vm.commentMessage
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        return _vm.addComment();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.commentMessage = $event.target.value;
      }
    }
  }), _vm._v(" "), _c('YButton', {
    staticClass: "block w-full py-2",
    attrs: {
      "color": "blue",
      "is-loading": _vm.isAdding,
      "disabled": !_vm.commentMessage
    },
    on: {
      "click": function ($event) {
        return _vm.addComment();
      }
    }
  }, [_vm._v("Add Comment")])], 1)])], 2), _vm.server.comments.length > 1 ? _c('v-popover', {
    attrs: {
      "offset": "10",
      "placement": "right-start"
    }
  }, [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Comment History',
      expression: "'Comment History'"
    }],
    attrs: {
      "type": "button"
    }
  }, [_c('Icon', {
    staticClass: "h-5 w-5 text-gray-400",
    attrs: {
      "name": "history"
    }
  })], 1), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "p-4 bg-gray-100 gap-4 flex flex-col"
  }, _vm._l(_vm.server.comments, function (comment) {
    var _comment$commenter;
    return _c('div', {
      key: comment.id,
      staticClass: "mb-1"
    }, [_c('p', [_vm._v(_vm._s(comment.message))]), _c('div', {
      staticClass: "flex"
    }, [_c('p', {
      staticClass: "text-xs text-gray-500 mr-4"
    }, [_vm._v(" " + _vm._s(_vm._f("luxonRelative")(comment.createdAt)) + " ")]), _c('p', {
      staticClass: "text-xs text-gray-500"
    }, [_vm._v(" " + _vm._s((_comment$commenter = comment.commenter) === null || _comment$commenter === void 0 ? void 0 : _comment$commenter.name) + " ")])])]);
  }), 0)])], 2) : _vm._e()], 1), _c('div', {
    staticClass: "flex flex-col place-self-start ml-10",
    staticStyle: {
      "width": "24rem"
    }
  }, [_c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_vm.server.comments.length > 0 ? _c('div', [_c('p', [_vm._v(_vm._s(_vm.server.comments[0].message))]), _c('div', {
    staticClass: "flex justify-between gap-2"
  }, [_c('p', {
    staticClass: "text-xs text-gray-500"
  }, [_vm._v(" " + _vm._s(_vm._f("luxonRelative")(_vm.server.comments[0].createdAt)) + " ")]), _c('p', {
    staticClass: "text-xs text-gray-500"
  }, [_vm._v(" " + _vm._s((_vm$server$comments$ = _vm.server.comments[0].commenter) === null || _vm$server$comments$ === void 0 ? void 0 : _vm$server$comments$.name) + " ")])])]) : _vm._e()])])])])]), _vm.server.ipSummary ? [_c('div', {
    staticClass: "text-gray-600 text-xs"
  }, [_vm._v(" Server IP: "), _c('EditableValue', {
    key: _vm.server.id,
    staticClass: "text-blue-600 font-semibold",
    attrs: {
      "type": "contentEditable",
      "value": _vm.server.serverIp,
      "mutation": _vm.UPDATE_SERVERS_MUTATION,
      "variables": {
        input: [{
          id: _vm.server.id,
          serverIp: '{value}'
        }]
      },
      "edit-focus-icon-enabled": false
    }
  })], 1), _vm.server.type != 'nameserver' ? _c('div', {
    staticClass: "text-gray-600 text-xs"
  }, [_vm._v(" Total IPs: "), _c('span', {
    staticClass: "text-blue-600 font-semibold"
  }, [_vm._v(_vm._s(_vm.server.ipSummary.count))])]) : _vm._e(), _vm.server.type != 'nameserver' ? _c('div', {
    staticClass: "text-gray-600 text-xs"
  }, [_vm._v(" Enabled IPs: "), _c('span', {
    staticClass: "text-green-600 font-semibold"
  }, [_vm._v(_vm._s(_vm.server.ipSummary.enabledCount))])]) : _vm._e(), _c('div', {
    staticClass: "text-gray-600 text-xs"
  }, [_vm._v(" Server Type: "), _vm.server.type == null ? _c('span', {
    staticClass: "text-blue-600 font-semibold"
  }, [_vm._v("proxy")]) : _c('span', {
    staticClass: "text-blue-600 font-semibold"
  }, [_vm._v(_vm._s(_vm.server.type))])])] : _vm._e()], 2), _c('div', [_c('div', {
    staticClass: "block text-right"
  }, [_c('server-actions', {
    ref: "serverActions",
    attrs: {
      "servers": [_vm.server],
      "provider": _vm.provider
    }
  })], 1)])]), _vm.server.type != 'nameserver' ? _c('div', [_c('ips', {
    attrs: {
      "show-ips-by-default": _vm.showIpsByDefault,
      "server": _vm.server,
      "value": _vm.server.ips
    }
  })], 1) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }