<template>
  <Widget>
    <WidgetBody class="p-3 p-md-4">
      <div class="flex items-center justify-between gap-10">
        <div class="flex items-center mb-3">
          <div class="mr-3">
            <h5 class="text-md font-bold text-inverse-800">
              {{ provider.name }}
            </h5>
          </div>
          <div>
            <Tag
              :color="provider.type == 'general' ? 'blue' : 'orange'"
              class="mr-1"
              :key="`${provider.type}`"
            >
              {{ provider.type.capitalize() }}
            </Tag>
          </div>
          <div class="ml-3 flex gap-2">
            <!-- add comment -->
            <v-popover offset="10" placement="right-start">
              <button type="button" v-tooltip="'Add Comment'">
                <Icon name="addComment" class="h-5 w-5 text-inverse-500" />
              </button>
              <template slot="popover">
                <form class="p-4 bg-gray-100 flex flex-col">
                  <textarea
                    class="px-3 py-3 w-full rounded mb-2 border block"
                    v-model="commentMessage"
                    placeholder="Type here..."
                    @keydown.exact.prevent.enter="addComment()"
                  />
                  <YButton
                    color="blue"
                    class="block w-full py-2"
                    @click="addComment()"
                    :is-loading="isAdding"
                    :disabled="!commentMessage"
                    >Add Comment</YButton
                  >
                </form>
              </template>
            </v-popover>
            <v-popover
              v-if="provider.comments.length > 1"
              offset="10"
              placement="right-start"
            >
              <button type="button" v-tooltip="'Comment History'">
                <Icon name="history" class="h-5 w-5 text-gray-400" />
              </button>
              <template slot="popover">
                <div class="p-4 bg-gray-100 gap-4 flex flex-col">
                  <div
                    v-for="comment in provider.comments"
                    :key="comment.id"
                    class="mb-1"
                  >
                    <p>{{ comment.message }}</p>
                    <div class="flex">
                      <p class="text-xs text-gray-500 mr-4">
                        {{ comment.createdAt | luxonRelative }}
                      </p>
                      <p class="text-xs text-gray-500">
                        {{ comment.commenter?.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </v-popover>
          </div>
        </div>

        <div class="flex flex-col place-self-start" style="width: 24rem;">
          <div class="flex items-center justify-between">
            <div class="flex flex-col">
              <div v-if="provider.comments.length > 0">
                <p>{{ provider.comments[0].message }}</p>
                <div class="flex justify-between gap-2">
                  <p class="text-xs text-gray-500">
                    {{ provider.comments[0].createdAt | luxonRelative }}
                  </p>
                  <p class="text-xs text-gray-500">
                    {{ provider.comments[0].commenter?.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-grow justify-end ">
          <provider-actions
            ref="providerActions"
            :providers="[provider]"
          ></provider-actions>
        </div>
      </div>
      <slot></slot>
    </WidgetBody>
  </Widget>
</template>

<script>
import ProviderUpdate from './ProviderUpdate'
import ServerCreate from './ServerCreate'
import ProviderActions from './ProviderActions'

import CREATE_COMMENT_MUTATION from '@/graphql/Comment/CreateCommentMutation.gql'

export default {
  components: {
    ProviderActions
  },
  props: {
    value: {
      type: Object
    },
    showServersByDefault: {
      type: Boolean,
      default: false
    }
  },
  apollo: {},
  data() {
    return {
      isLoading: false,
      showServers: this.showServersByDefault,
      provider: JSON.parse(JSON.stringify(this.value)),
      commentMessage: null,
      isAdding: false
    }
  },
  watch: {
    showServersByDefault: function() {
      this.showServers = this.showServersByDefault
    },
    value: function(newValue) {
      this.provider = newValue
    },
    provider: {
      handler: function(newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    }
  },
  computed: {
    // serverTypes(){
    //   if(!this.provider.servers){
    //     return []
    //   }
    //   let types = this.provider.servers.map((server)=>{return server.type})
    //   types = [...new Set(types)].sort()
    //   return types
    // }
  },
  mounted() {},
  updated() {},

  methods: {
    popUpdateProviderModal() {
      this.$modal.show(
        ProviderUpdate,
        {
          value: this.provider
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 1000,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    popCreateServerModal() {
      this.$modal.show(
        ServerCreate,
        {
          provider: this.provider
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 1000,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    addComment() {
      this.isAdding = true
      this.$apollo
        .mutate({
          mutation: CREATE_COMMENT_MUTATION,
          variables: {
            input: {
              modelId: this.provider.id,
              modelClass: 'YdnsProvider',
              message: this.commentMessage,
              commentType: null,
              tags: []
            }
          }
        })
        .then(() => {
          this.isAdding = false
          this.commentMessage = null
          this.$events.emit('refreshYdnsProviders')
        })
        .catch(error => {
          this.isAdding = false
          console.error(error)
        })
    }
  }
}
</script>

<style></style>
