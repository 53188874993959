var render = function render(){
  var _vm$provider$comments;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetBody', {
    staticClass: "p-3 p-md-4"
  }, [_c('div', {
    staticClass: "flex items-center justify-between gap-10"
  }, [_c('div', {
    staticClass: "flex items-center mb-3"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_c('h5', {
    staticClass: "text-md font-bold text-inverse-800"
  }, [_vm._v(" " + _vm._s(_vm.provider.name) + " ")])]), _c('div', [_c('Tag', {
    key: `${_vm.provider.type}`,
    staticClass: "mr-1",
    attrs: {
      "color": _vm.provider.type == 'general' ? 'blue' : 'orange'
    }
  }, [_vm._v(" " + _vm._s(_vm.provider.type.capitalize()) + " ")])], 1), _c('div', {
    staticClass: "ml-3 flex gap-2"
  }, [_c('v-popover', {
    attrs: {
      "offset": "10",
      "placement": "right-start"
    }
  }, [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Add Comment',
      expression: "'Add Comment'"
    }],
    attrs: {
      "type": "button"
    }
  }, [_c('Icon', {
    staticClass: "h-5 w-5 text-inverse-500",
    attrs: {
      "name": "addComment"
    }
  })], 1), _c('template', {
    slot: "popover"
  }, [_c('form', {
    staticClass: "p-4 bg-gray-100 flex flex-col"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.commentMessage,
      expression: "commentMessage"
    }],
    staticClass: "px-3 py-3 w-full rounded mb-2 border block",
    attrs: {
      "placeholder": "Type here..."
    },
    domProps: {
      "value": _vm.commentMessage
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        return _vm.addComment();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.commentMessage = $event.target.value;
      }
    }
  }), _vm._v(" "), _c('YButton', {
    staticClass: "block w-full py-2",
    attrs: {
      "color": "blue",
      "is-loading": _vm.isAdding,
      "disabled": !_vm.commentMessage
    },
    on: {
      "click": function ($event) {
        return _vm.addComment();
      }
    }
  }, [_vm._v("Add Comment")])], 1)])], 2), _vm.provider.comments.length > 1 ? _c('v-popover', {
    attrs: {
      "offset": "10",
      "placement": "right-start"
    }
  }, [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Comment History',
      expression: "'Comment History'"
    }],
    attrs: {
      "type": "button"
    }
  }, [_c('Icon', {
    staticClass: "h-5 w-5 text-gray-400",
    attrs: {
      "name": "history"
    }
  })], 1), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "p-4 bg-gray-100 gap-4 flex flex-col"
  }, _vm._l(_vm.provider.comments, function (comment) {
    var _comment$commenter;
    return _c('div', {
      key: comment.id,
      staticClass: "mb-1"
    }, [_c('p', [_vm._v(_vm._s(comment.message))]), _c('div', {
      staticClass: "flex"
    }, [_c('p', {
      staticClass: "text-xs text-gray-500 mr-4"
    }, [_vm._v(" " + _vm._s(_vm._f("luxonRelative")(comment.createdAt)) + " ")]), _c('p', {
      staticClass: "text-xs text-gray-500"
    }, [_vm._v(" " + _vm._s((_comment$commenter = comment.commenter) === null || _comment$commenter === void 0 ? void 0 : _comment$commenter.name) + " ")])])]);
  }), 0)])], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "flex flex-col place-self-start",
    staticStyle: {
      "width": "24rem"
    }
  }, [_c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_vm.provider.comments.length > 0 ? _c('div', [_c('p', [_vm._v(_vm._s(_vm.provider.comments[0].message))]), _c('div', {
    staticClass: "flex justify-between gap-2"
  }, [_c('p', {
    staticClass: "text-xs text-gray-500"
  }, [_vm._v(" " + _vm._s(_vm._f("luxonRelative")(_vm.provider.comments[0].createdAt)) + " ")]), _c('p', {
    staticClass: "text-xs text-gray-500"
  }, [_vm._v(" " + _vm._s((_vm$provider$comments = _vm.provider.comments[0].commenter) === null || _vm$provider$comments === void 0 ? void 0 : _vm$provider$comments.name) + " ")])])]) : _vm._e()])])]), _c('div', {
    staticClass: "flex flex-grow justify-end"
  }, [_c('provider-actions', {
    ref: "providerActions",
    attrs: {
      "providers": [_vm.provider]
    }
  })], 1)]), _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }