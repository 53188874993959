var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.campaign ? _c('div', {
    staticClass: "pt-4 w-100 pr-8"
  }, [_c('div', {
    staticClass: "x-row",
    staticStyle: {
      "align-items": "flex-start"
    }
  }, [_c('div', {
    staticClass: "x-col1"
  }, [_vm._v(" Baseline Minimums ")]), _c('div', {
    staticClass: "x-col2 flex flex-row gap-12"
  }, [_c('div', {
    staticClass: "flex flex-col gap-2"
  }, [_c('div', [_c('Superselect', {
    attrs: {
      "title": "Geos",
      "multiple": false,
      "options": _vm.baselineCountries,
      "placeholder": "Select"
    },
    model: {
      value: _vm.baselineGeo,
      callback: function ($$v) {
        _vm.baselineGeo = $$v;
      },
      expression: "baselineGeo"
    }
  })], 1), _c('div', [_c('YInput', {
    attrs: {
      "label": "Baseline"
    },
    model: {
      value: _vm.baselineValue,
      callback: function ($$v) {
        _vm.baselineValue = $$v;
      },
      expression: "baselineValue"
    }
  })], 1), _c('div', {
    staticClass: "flex gap-2"
  }, [_c('YInput', {
    attrs: {
      "label": "Minimum Deposit"
    },
    model: {
      value: _vm.baselineMinimum,
      callback: function ($$v) {
        _vm.baselineMinimum = $$v;
      },
      expression: "baselineMinimum"
    }
  }), _c('YButton', {
    staticClass: "bg-inverse-700 text-white h-8 w-8 rounded flex justify-center items-center",
    attrs: {
      "disabled": !_vm.baselineGeo || !_vm.baselineValue || !_vm.baselineMinimum
    },
    on: {
      "click": _vm.addNewBaselineRow
    }
  }, [_c('Icon', {
    attrs: {
      "name": "plus",
      "size": "4"
    }
  })], 1)], 1)]), _vm.baselineDeposits.length > 0 ? _c('YTable', {
    staticClass: "m-0 p-0",
    attrs: {
      "data": _vm.baselineDeposits
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(" " + _vm._s(props.rowData.geo) + " ")]), _c('td', [_vm._v(_vm._s(props.rowData.baseline))]), _c('td', [_vm._v(_vm._s(props.rowData.minimum))]), _c('td', {
          staticStyle: {
            "width": "20px"
          }
        }, [_c('div', {
          on: {
            "click": function ($event) {
              return _vm.deleteRow(props.rowData);
            }
          }
        }, [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: 'Delete Row',
            expression: "'Delete Row'"
          }],
          staticClass: "text-red-600",
          attrs: {
            "name": "trashCan",
            "size": 4
          }
        })], 1)])];
      }
    }], null, false, 736782809)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Geo")]), _c('th', [_vm._v("Baseline")]), _c('th', [_vm._v("Min")]), _c('th')])], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1"
  }, [_vm._v("Images")]), _c('div', {
    staticClass: "x-col2 flex flex-row gap-2"
  }, [_vm.campaign.showFrontend ? _c('div', [_c('ImageManager', {
    attrs: {
      "availableTypes": _vm.campaign.showFrontend === 'All In Affiliates' ? ['logo', 'logo-dark'] : undefined,
      "images": _vm.campaign.images
    },
    on: {
      "submitted": function (image) {
        _vm.saveImage(image);
      }
    }
  })], 1) : _vm._e(), !_vm.campaign.showFrontend ? _c('div', {
    staticClass: "font-semibold text-gray-400 text-xs"
  }, [_vm._v(" Frontend required ")]) : _vm._e()])]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.smartLinkEditable.toggleCheckbox();
      }
    }
  }, [_vm._v(" Smart Link ")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('EditableValue', {
    ref: "smartLinkEditable",
    attrs: {
      "value": _vm.campaign.smartLink,
      "mutation": _vm.UPDATE_CAMPAIGN_MUTATION,
      "type": "checkbox",
      "variables": {
        input: {
          id: _vm.campaign.id,
          smartLink: '{value}'
        }
      }
    }
  })], 1), _vm.campaign.smartLink == 1 ? _c('span', {
    staticClass: "flex-grow flex items-center"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.smartLinkEditable.toggleCheckbox();
      }
    }
  }, [_vm._v(" Prefered Algorithm ")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('EditableValue', {
    ref: "prefferedAlgorithmEditable",
    attrs: {
      "value": _vm.campaign.preferredAlgorithmId,
      "type": "slot",
      "mutation": _vm.UPDATE_CAMPAIGN_MUTATION,
      "variables": {
        input: {
          id: _vm.campaign.id,
          preferredAlgorithmId: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag,
            staticClass: "mr-1 my-1",
            attrs: {
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(_vm._s(tag))]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full w-24",
          attrs: {
            "title": "Algorithm Id",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": false,
            "placeholder": "Select",
            "options": ['2', '7']
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            },
            "close": function () {}
          }
        })];
      }
    }], null, false, 3721512040)
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.featuredEditable.toggleCheckbox();
      }
    }
  }, [_vm._v(" Featured ")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('EditableValue', {
    ref: "featuredEditable",
    attrs: {
      "value": _vm.campaign.isFeatured,
      "mutation": _vm.UPDATE_CAMPAIGN_MUTATION,
      "type": "checkbox",
      "variables": {
        input: {
          id: _vm.campaign.id,
          isFeatured: '{value}'
        }
      }
    }
  })], 1), _c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.isPinnedEditable.toggleCheckbox();
      }
    }
  }, [_vm._v(" Pinned Campaign ")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('EditableValue', {
    ref: "isPinnedEditable",
    attrs: {
      "value": _vm.campaign.isPinned,
      "mutation": _vm.UPDATE_CAMPAIGN_MUTATION,
      "type": "checkbox",
      "variables": {
        input: {
          id: _vm.campaign.id,
          isPinned: '{value}'
        }
      }
    }
  })], 1)]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.smartLinkEditable.toggleCheckbox();
      }
    }
  }, [_vm._v(" Show Front-end ")]), _c('div', {
    staticClass: "x-col2"
  }, [_vm.campaign.showFrontend ? _c('div', [_vm.campaign.showFrontend && _vm.campaign.showFrontend == 'yTrack' ? _c('div', [_c('Tag', {
    staticClass: "mr-1 mb-1",
    attrs: {
      "color": "blue"
    }
  }, [_vm._v(" " + _vm._s(_vm.campaign.showFrontend) + " ")])], 1) : _vm._e(), _vm.campaign.showFrontend && _vm.campaign.showFrontend == 'All In Affiliates' ? _c('div', [_c('Tag', {
    staticClass: "mr-1 mb-1",
    attrs: {
      "color": "indigo"
    }
  }, [_vm._v(" " + _vm._s(_vm.campaign.showFrontend) + " ")])], 1) : _vm._e()]) : _c('div', {
    staticClass: "x-col2"
  }, [_c('EditableValue', {
    ref: "prefferedAlgorithmEditable",
    attrs: {
      "value": _vm.campaign.showFrontend,
      "type": "slot",
      "customGlobalEmit": "fallbackCampaignUpdated",
      "mutation": _vm.UPDATE_CAMPAIGN_MUTATION,
      "variables": {
        input: {
          id: _vm.campaign.id,
          showFrontend: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', [_c('Tag', {
          staticClass: "mr-1 my-1",
          attrs: {
            "tabindex": "0"
          },
          on: {
            "focus": function () {
              slotProps.focus();
            }
          }
        }, [_vm._v(_vm._s(slotProps.value))])], 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Frontend",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": false,
            "placeholder": "Select",
            "options": ['All In Affiliates', 'yTrack']
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            },
            "close": function () {}
          }
        })];
      }
    }], null, false, 2435199430)
  })], 1)])]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1"
  }, [_vm._v("Brand License")]), _c('div', {
    staticClass: "x-col2"
  }, [_vm.licenseOptions ? _c('EditableValue', {
    ref: "licenseEditable",
    attrs: {
      "title": "License",
      "value": _vm.licenseValue,
      "trackby": "label",
      "label": "label",
      "mutation": _vm.UPDATE_CAMPAIGN_MUTATION,
      "input-style": "min-width: 14rem; max-width: 100%;",
      "type": "dropdown",
      "dropdownOptions": _vm.licenseOptions || [],
      "variables": {
        input: {
          id: _vm.campaign.id,
          license: '{value}'
        }
      }
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.approvalRequiredTypesEditable.focus();
      }
    }
  }, [_vm._v(" Approval Required ")]), _c('div', {
    staticClass: "x-col2"
  }, [_vm.campaign.payoutType == 'cpa' || _vm.campaign.payoutType == 'cpl' ? _c('div', [_c('EditableValue', {
    ref: "approvalRequiredTypesEditable",
    attrs: {
      "value": _vm.campaign.approvalRequiredTypes,
      "type": "slot",
      "mutation": _vm.UPDATE_CAMPAIGN_MUTATION,
      "variables": {
        input: {
          id: _vm.campaign.id,
          approvalRequiredTypes: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": tag,
              "tabindex": "0"
            }
          }, [_vm._v(_vm._s(tag))]);
        }), 1) : _c('div', [_c('span', {
          staticClass: "italic text-gray-500"
        }, [_vm._v("None")])])];
      }
    }], null, false, 3527061904)
  })], 1) : _c('div', [_c('EditableValue', {
    ref: "approvalRequiredTypesEditable",
    attrs: {
      "value": _vm.campaign.approvalRequiredTypes,
      "type": "slot",
      "mutation": _vm.UPDATE_CAMPAIGN_MUTATION,
      "variables": {
        input: {
          id: _vm.campaign.id,
          approvalRequiredTypes: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": tag,
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(_vm._s(tag))]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticStyle: {
            "min-width": "10rem"
          },
          attrs: {
            "title": "Approval Required",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "placeholder": "Select",
            "options": ['all', 'quarantined', 'quality', 'fraud', 'compliance']
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            },
            "close": function () {
              //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
            }
          }
        })];
      }
    }], null, false, 2351131820)
  })], 1)])]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": _vm.openPermissionsModal
    }
  }, [_vm._v("Access Rules")]), _c('div', {
    staticClass: "x-col2"
  }, [_vm.campaign && !_vm.campaign.showFrontend ? [_c('div', {
    staticClass: "text-xs font-semibold uppercase italic text-gray-500"
  }, [_vm._v(" Show Front-end selection required ")])] : _vm._e(), _vm.campaign.publisherAbilitySummary ? [_c('div', {
    staticClass: "box-width"
  }, [_c('div', {
    staticClass: "overflow-y-scroll rounded cursor-pointer hover:shadow-md focus:shadow-md outline-none",
    staticStyle: {
      "max-height": "8rem"
    },
    attrs: {
      "tabindex": "0"
    },
    on: {
      "click": _vm.openPermissionsModal,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.openPermissionsModal.apply(null, arguments);
      }
    }
  }, [_vm.campaign.publisherAbilitySummary.all ? _c('Tag', {
    staticClass: "block py-2 rounded-none",
    attrs: {
      "color": "gray",
      "shade": 100
    }
  }, [_vm._v("All Publishers")]) : _vm._e(), _vm._l(_vm.campaign.publisherAbilitySummary.only, function (only) {
    return _c('Tag', {
      key: only.id,
      staticClass: "block py-2 rounded-none",
      attrs: {
        "color": "blue",
        "shade": 100
      }
    }, [_vm._v(_vm._s(only.label))]);
  }), _vm._l(_vm.campaign.publisherAbilitySummary.except, function (except) {
    return _c('Tag', {
      key: except.id,
      staticClass: "block py-2 rounded-none bg-red-100 text-red-600",
      attrs: {
        "color": "blue",
        "shade": 100
      }
    }, [_vm._v(_vm._s(except.label))]);
  })], 2), _c('div', {
    staticClass: "text-right"
  }, [_vm.campaign.publisherAbilitySummary.except.length ? _c('small', {
    staticClass: "font-semibold text-red-600"
  }, [_vm._v(" " + _vm._s(_vm.campaign.publisherAbilitySummary.except.length) + " Excluded ")]) : _vm._e(), _vm.campaign.publisherAbilitySummary.only.length ? _c('small', {
    staticClass: "font-semibold text-blue-600"
  }, [_vm._v(_vm._s(_vm.campaign.publisherAbilitySummary.only.length) + " Only")]) : _vm._e()])])] : _vm._e()], 2)]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1"
  }, [_vm._v("Subscribers")]), _c('div', {
    staticClass: "x-col2"
  }, [_vm.campaign && !_vm.campaign.showFrontend ? [_c('div', {
    staticClass: "text-xs font-semibold uppercase italic text-gray-500"
  }, [_vm._v(" Show Front-end selection required ")])] : _vm._e(), _vm.campaign && _vm.campaign.subscribers ? [_c('div', {
    staticClass: "box-width"
  }, [_c('div', {
    staticClass: "overflow-y-scroll rounded cursor-pointer hover:shadow-md focus:shadow-md outline-none",
    staticStyle: {
      "max-height": "8rem"
    }
  }, _vm._l(_vm.campaign.subscribers, function (user) {
    return _c('router-link', {
      key: user.id,
      staticClass: "link truncate block hover:underline",
      attrs: {
        "to": {
          name: 'User',
          params: {
            id: user.id
          }
        }
      }
    }, [_c('Tag', {
      staticClass: "block py-2 rounded-none",
      attrs: {
        "color": "blue",
        "shade": 100,
        "hover": true
      }
    }, [_vm._v(_vm._s(user.label))])], 1);
  }), 1), _c('div', {
    staticClass: "flex justify-between"
  }, [_c('div', [_c('small', [_c('a', {
    staticClass: "link cursor-pointer mt-1 block font-semibold",
    attrs: {
      "href": "#",
      "tabindex": "0"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openSubscribeUserModal.apply(null, arguments);
      }
    }
  }, [_vm._v("Add Subscribers")])])]), _c('div', [_vm.campaign.subscribers ? _c('small', {
    staticClass: "font-semibold text-gray-700 text-blue-600 hover:text-blue-500 cursor-pointer",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openSubscribeUserModal.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.campaign.subscribers.length) + " Subscriber"), _vm.campaign.subscribers.length > 1 ? [_vm._v("s")] : _vm._e()], 2) : _vm._e()])])])] : _vm._e()], 2)]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'Campaign',
          params: {
            id: _vm.id,
            tab: 'offers'
          }
        });
      }
    }
  }, [_vm._v(" Advertisers ")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('div', {
    staticClass: "box-width"
  }, [_c('div', {
    staticClass: "overflow-y-scroll rounded cursor-pointer hover:shadow-md focus:shadow-md outline-none",
    staticStyle: {
      "max-height": "8rem"
    }
  }, _vm._l(_vm.advertisers, function (user) {
    return _c('router-link', {
      key: user.id,
      staticClass: "link truncate block hover:underline",
      attrs: {
        "to": {
          name: 'User',
          params: {
            id: user.id
          }
        }
      }
    }, [_c('Tag', {
      staticClass: "block py-2 rounded-none",
      attrs: {
        "color": "blue",
        "shade": 100,
        "hover": true
      }
    }, [_vm._v(_vm._s(user.label))])], 1);
  }), 1), _c('div', {
    staticClass: "flex justify-between"
  }, [_c('div'), _c('div', [_vm.campaign.offers ? _c('small', {
    staticClass: "font-semibold text-gray-700 text-blue-600 hover:text-blue-700 cursor-pointer",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push({
          name: 'Campaign',
          params: {
            id: _vm.id,
            tab: 'offers'
          }
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.advertisers.length) + " Advertiser"), _vm.advertisers.length > 1 ? [_vm._v("s")] : _vm._e()], 2) : _vm._e()])])])])]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.verticalsEditable.focus();
      }
    }
  }, [_vm._v(" Verticals ")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('EditableValue', {
    ref: "verticalsEditable",
    attrs: {
      "value": _vm.campaign.tags.filter(function (tag) {
        return tag.type === 'vertical';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          type: 'vertical',
          taggableType: 'campaign',
          taggableId: _vm.campaign.id,
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": tag.color,
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(_vm._s(tag.name))]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticStyle: {
            "min-width": "10rem"
          },
          attrs: {
            "title": "Verticals",
            "value": slotProps.value[0],
            "focusOnMount": true,
            "multiple": false,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['vertical']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue([value]);
            },
            "close": function () {
              //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
            }
          }
        })];
      }
    }], null, false, 2125304152)
  })], 1)]), _c('div', {
    staticClass: "flex items-center pt-4 pb-1"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.tagsEditable.focus();
      }
    }
  }, [_vm._v("Browser Type")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('EditableValue', {
    ref: "tagsEditable",
    attrs: {
      "value": _vm.campaign.tags.filter(function (tag) {
        return tag.type === 'campaignBrowser';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "trackBy": "type",
      "variables": {
        input: {
          type: 'campaignBrowser',
          taggableType: 'campaign',
          taggableId: _vm.campaign.id,
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": tag.color,
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(_vm._s(tag.name))]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['campaignBrowser']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            },
            "close": function () {
              //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
            }
          }
        })];
      }
    }], null, false, 3683562717)
  })], 1)]), _c('div', {
    staticClass: "flex items-center pt-4 pb-1"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.tagsEditable.focus();
      }
    }
  }, [_vm._v("Campaign Tags")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('EditableValue', {
    ref: "tagsEditable",
    attrs: {
      "value": _vm.campaign.tags.filter(function (tag) {
        return tag.type === 'campaign';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "trackBy": "type",
      "variables": {
        input: {
          type: 'campaign',
          taggableType: 'campaign',
          taggableId: _vm.campaign.id,
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": tag.color,
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(_vm._s(tag.name))]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['campaign']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            },
            "close": function () {
              //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
            }
          }
        })];
      }
    }], null, false, 348586993)
  })], 1)]), _c('div', {
    staticClass: "flex items-center pt-1 pb-4"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.tagsEditable.focus();
      }
    }
  }, [_vm._v("OS Type")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('EditableValue', {
    ref: "tagsEditable",
    attrs: {
      "value": _vm.campaign.tags.filter(function (tag) {
        return tag.type === 'campaignOS';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "trackBy": "type",
      "variables": {
        input: {
          type: 'campaignOS',
          taggableType: 'campaign',
          taggableId: _vm.campaign.id,
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": tag.color,
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(_vm._s(tag.name))]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['campaignOS']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            },
            "close": function () {
              //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
            }
          }
        })];
      }
    }], null, false, 1871637581)
  })], 1)]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.allowedTagsEditable.focus();
      }
    }
  }, [_vm._v(" Traffic Type ")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('EditableValue', {
    ref: "allowedTagsEditable",
    staticClass: "inline",
    attrs: {
      "value": _vm.campaign.tags.filter(function (tag) {
        return tag.type === 'trafficType' && tag.isNot === false;
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          type: 'trafficType',
          taggableType: 'campaign',
          taggableId: _vm.campaign.id,
          tags: '{value}',
          isNot: false,
          detachMode: 'SAME_POLARITY'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [_c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": tag.color,
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(_vm._s(tag.name))]);
        }), 1)];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Allowed",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['trafficType']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            },
            "close": function () {
              //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
            }
          }
        })];
      }
    }], null, false, 2543993200)
  })], 1)]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1",
    on: {
      "click": function ($event) {
        return _vm.$refs.restrictedTagsEditable.focus();
      }
    }
  }, [_vm._v(" Restricted ")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('EditableValue', {
    ref: "restrictedTagsEditable",
    attrs: {
      "value": _vm.campaign.tags.filter(function (tag) {
        return tag.type === 'trafficType' && tag.isNot === true;
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          type: 'trafficType',
          taggableType: 'campaign',
          taggableId: _vm.campaign.id,
          tags: '{value}',
          isNot: true,
          detachMode: 'SAME_POLARITY'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [_c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "red",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(_vm._s(tag.name))]);
        }), 1)];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full border",
          attrs: {
            "title": "Restricted",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['trafficType']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            },
            "close": function () {
              //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
            }
          }
        })];
      }
    }], null, false, 480132587)
  })], 1)]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col1"
  }, [_vm._v("Actions")]), _c('div', {
    staticClass: "x-col2"
  }, [_c('ClickAndReplace', {
    attrs: {
      "duration": 2000
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('YButton', {
          staticClass: "bg-gray-100 hover:bg-gray-200 text-gray-700",
          attrs: {
            "is-loading": _vm.isDeleting
          }
        }, [_vm._v("Delete Campaign")])];
      },
      proxy: true
    }, {
      key: "replacement",
      fn: function () {
        return [_c('YButton', {
          staticClass: "bg-red-600 hover:bg-red-700 text-white",
          attrs: {
            "is-loading": _vm.isDeleting
          },
          on: {
            "click": _vm.deleteCampaign
          }
        }, [_vm._v("Confirm Delete")])];
      },
      proxy: true
    }], null, false, 3928856800)
  })], 1)])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }