<template>
  <div
    class="my-2 p-3"
    style="border: 1px solid rgba(0,0,0,0.1); border-radius: 3px; box-shadow: 1px 1px 4px rgba(0,0,0,0.06)"
  >
    <div class="flex w-full">
      <!-- <div class="ts-6 text-gray-600 p-3 pr-6 none block-md">
        <Icon name="dns" :size="4" />
      </div> -->
      <div class="flex-grow-md-1 w-full w-md-auto">
        <div class="flex justify-between">
          <div>
            <div class="flex items-center mb-2">
              <div class="mr-3 flex gap-2">
                <h6 class="mb-0 font-bold">
                  <a
                    v-if="server.type == 'proxy'"
                    v-bind:href="'http://' + server.serverIp + '/haproxy?stats'"
                  >
                    <Icon name="information" :size="6" class="inline -mx-1" />
                  </a>
                  <template v-if="server.hostname">
                    {{ server.hostname }}
                  </template>
                  <template v-else>
                    {{ server.provider.name }} - {{ server.id }}
                  </template>
                </h6>

                <div class="ml-3 flex gap-2">
                  <!-- add comment -->
                  <v-popover
                    ref="commentPopover"
                    offset="10"
                    placement="right-start"
                  >
                    <button type="button" v-tooltip="'Add Comment'">
                      <Icon
                        name="addComment"
                        class="h-5 w-5 text-inverse-500"
                      />
                    </button>
                    <template slot="popover">
                      <form class="p-4 bg-gray-100 flex flex-col">
                        <textarea
                          class="px-3 py-3 w-full rounded mb-2 border block"
                          v-model="commentMessage"
                          placeholder="Type here..."
                          @keydown.exact.prevent.enter="addComment()"
                        />
                        <YButton
                          color="blue"
                          class="block w-full py-2"
                          @click="addComment()"
                          :is-loading="isAdding"
                          :disabled="!commentMessage"
                          >Add Comment</YButton
                        >
                      </form>
                    </template>
                  </v-popover>
                  <!-- comment history -->
                  <v-popover
                    v-if="server.comments.length > 1"
                    offset="10"
                    placement="right-start"
                  >
                    <button type="button" v-tooltip="'Comment History'">
                      <Icon name="history" class="h-5 w-5 text-gray-400" />
                    </button>
                    <template slot="popover">
                      <div class="p-4 bg-gray-100 gap-4 flex flex-col">
                        <div
                          v-for="comment in server.comments"
                          :key="comment.id"
                          class="mb-1"
                        >
                          <p>{{ comment.message }}</p>
                          <div class="flex">
                            <p class="text-xs text-gray-500 mr-4">
                              {{ comment.createdAt | luxonRelative }}
                            </p>
                            <p class="text-xs text-gray-500">
                              {{ comment.commenter?.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-popover>
                </div>
                <div
                  class="flex flex-col place-self-start ml-10"
                  style="width: 24rem;"
                >
                  <div class="flex items-center justify-between">
                    <div class="flex flex-col">
                      <div v-if="server.comments.length > 0">
                        <p>{{ server.comments[0].message }}</p>
                        <div class="flex justify-between gap-2">
                          <p class="text-xs text-gray-500">
                            {{ server.comments[0].createdAt | luxonRelative }}
                          </p>
                          <p class="text-xs text-gray-500">
                            {{ server.comments[0].commenter?.name }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <template v-if="server.ipSummary">
              <div class="text-gray-600 text-xs">
                Server IP:
                <EditableValue
                  :key="server.id"
                  type="contentEditable"
                  :value="server.serverIp"
                  :mutation="UPDATE_SERVERS_MUTATION"
                  :variables="{
                    input: [{ id: server.id, serverIp: '{value}' }]
                  }"
                  class="text-blue-600 font-semibold"
                  :edit-focus-icon-enabled="false"
                />
              </div>
              <div
                v-if="server.type != 'nameserver'"
                class="text-gray-600 text-xs"
              >
                Total IPs:
                <span class="text-blue-600 font-semibold">{{
                  server.ipSummary.count
                }}</span>
              </div>
              <div
                v-if="server.type != 'nameserver'"
                class="text-gray-600 text-xs"
              >
                Enabled IPs:
                <span class="text-green-600 font-semibold">{{
                  server.ipSummary.enabledCount
                }}</span>
              </div>
              <div class="text-gray-600 text-xs">
                Server Type:
                <span
                  v-if="server.type == null"
                  class="text-blue-600 font-semibold"
                  >proxy</span
                >
                <span v-else class="text-blue-600 font-semibold">{{
                  server.type
                }}</span>
              </div>
            </template>
          </div>

          <div>
            <div class="block text-right">
              <server-actions
                ref="serverActions"
                :servers="[server]"
                :provider="provider"
              ></server-actions>
            </div>
          </div>
        </div>
        <div v-if="server.type != 'nameserver'">
          <ips
            :show-ips-by-default="showIpsByDefault"
            :server="server"
            :value="server.ips"
          ></ips>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ips from './Ips'
import ServerActions from './ServerActions'
import UPDATE_SERVERS_MUTATION from '@/graphql/DomainProvider/UpdateServersMutation.gql'
import CREATE_COMMENT_MUTATION from '@/graphql/Comment/CreateCommentMutation.gql'

export default {
  components: {
    Ips,
    ServerActions
  },
  props: {
    value: {
      type: Object
    },
    showIpsByDefault: {
      type: Boolean,
      default: false
    },
    provider: {
      type: Object
    }
  },
  apollo: {},
  data() {
    return {
      UPDATE_SERVERS_MUTATION,
      isLoading: false,
      commentMessage: null,
      isAdding: false
    }
  },
  computed: {
    server() {
      return this.value
    },
    serverIsUp() {
      var numberOfSuccessfulChecks = this.value.checks.filter(
        check => check['status'] === 'success'
      ).length
      return this.value.checks.length === numberOfSuccessfulChecks
    }
  },
  watch: {},
  mounted() {},

  updated() {},

  methods: {
    addComment() {
      this.isAdding = true
      this.$apollo
        .mutate({
          mutation: CREATE_COMMENT_MUTATION,
          variables: {
            input: {
              modelId: this.server.id,
              modelClass: 'YdnsServer',
              message: this.commentMessage,
              commentType: null,
              tags: []
            }
          }
        })
        .then(() => {
          this.$refs.commentPopover.hide()
          this.isAdding = false
          this.$events.emit('refreshYdnsServers')
          this.commentMessage = null
        })
        .catch(error => {
          this.$refs.commentPopover.hide()
          this.isAdding = false
          console.error(error)
        })
    }
  }
}
</script>

<style scoped></style>
